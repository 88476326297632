import { motion } from 'framer-motion';
import styled from 'styled-components';

export const ScFaqCardWrapper = styled.div`
	height: 126px;
	display: flex;
	align-items: center;
	gap: 24px;
	align-self: stretch;
	margin-top: 45px;
	margin-bottom: 25px;
	img {
		width: 120px;
	}
`;

export const ScCalculatorAccordionWrapper = styled(motion.div)`
	display: flex;
	flex-direction: column;
	gap: 8px;
	margin-top: 8px;
	@media (min-width: 1220px) {
		gap: 24px;
		margin-top: 24px;
	}
`;

export const ScFaqTitle = styled(motion.p)`
	font: ${({ theme }) => theme.fonts.faq.label};
`;

export const ScTitleWrapper = styled(motion.div)`
	display: flex;
	flex-direction: column;
	gap: 8px;
	margin-top: 8px;
	margin-bottom: 50px;
	h1 {
		font: ${({ theme }) => theme.fonts.faq.title};
		margin-bottom: 40px;
	}
`;

export const ScFaqSectionsWrapper = styled(motion.div)`
	max-width: 1200px;
	margin: 0 auto 50px;
`;

export const ScButtonWrapper = styled(motion.div)`
	display: flex;
	flex-direction: row;
	gap: 8px;
	margin-top: 8px;
	@media (min-width: 1220px) {
		button {
			padding: 24px 56px;
		}
	}
`;

export const ScReadyToStartSavingButtonsWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 10px;
	margin-top: 30px;
	width: fit-content;
	@media (min-width: 768px) {
		flex-direction: row;
		gap: 24px;
	}
	@media (min-width: 1220px) {
		margin-top: 50px;
		flex-wrap: wrap;
		gap: 10px;
	}
	@media (min-width: 1440px) {
	}
`;

export const ScReadyToStartSavingTextWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 25px;
	height: 100%;
	button {
		width: 145px;
	}
	@media (min-width: 360px) {
		align-items: center;
	}
	@media (min-width: 768px) {
		button {
			width: fit-content;
		}
	}
	@media (min-width: 1220px) {
		justify-content: space-between;
		align-items: flex-start;
		gap: 40px;
		p {
			font: ${({ theme }) => theme.fonts.faqAccordionHeading.enormousText};
			max-width: 680px;
		}
	}
	@media (min-width: 1440px) {
		justify-content: space-between;
		gap: 40px;
		p {
			max-width: 960px;
		}
	}
	@media (min-width: 1920px) {
		.text {
			max-width: 968px;
		}
		p {
			max-width: 968px;
		}
	}
`;

export const ScReadyToStartSavingWrapper = styled.div`
	background: ${({ theme }) => theme.colors.marmelade};
	border-radius: 10px;
	padding: 24px 15px 50px 15px;
	box-sizing: border-box;
	margin-bottom: 40px;
	display: flex;
	flex-direction: column;
	gap: 24px;
	img {
		width: 225px;
		height: auto;
	}
	@media (min-width: 360px) {
		align-items: center;
		img {
			width: 60%;
		}
	}
	@media (min-width: 1220px) {
		border-radius: 30px;
		padding: 40px 60px;
		display: grid;
		grid-template-columns: 10fr 6fr;
		gap: 60px;
		img {
			width: 100%;
			height: auto;
			margin: auto 0;
		}
		margin-top: 80px;
	}
	@media (min-width: 1440px) {
		margin-top: 120px;
		padding: 40px 60px 40px 60px;
		grid-template-columns: 17fr 7fr;
	}
	@media (min-width: 1450px) {
		margin-top: 120px;
		padding: 40px 60px 40px 60px;
		grid-template-columns: 14fr 7fr;
	}
	@media (min-width: 1920px) {
		img {
			margin: 0;
		}
		grid-template-columns: 135fr 58fr;
		gap: 93px;
	}
`;
