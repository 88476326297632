import React from 'react';
import { motion } from 'framer-motion';
import { useTheme } from 'styled-components';

import { MotionDiv, MotionImage } from '../../animations/components';
import { animationContainer, fadeInLeft, fadeInRight, fadeInUp } from '../../animations/variants';
import image from '../../assets/images/ready-to-start-saving-faq.png';
import { ScContainer } from '../../components/container/styled';
import { ScParagraph } from '../../components/Paragraph/styled';
import { ScTitle } from '../../components/Title/styled';
import useContentAnimation from '../../hooks/use-content-animation';
import { useMatchMedia } from '../../hooks/use-match-media';

import {
	ScReadyToStartSavingButtonsWrapper,
	ScReadyToStartSavingTextWrapper,
	ScReadyToStartSavingWrapper,
} from './styles';

export const ReadyToStartSaving = ({
	title,
	text,
	children,
}: {
	title: string;
	text: string;
	children: React.ReactNode;
}) => {
	const theme = useTheme();
	const isDesktop = useMatchMedia(`(min-width: 1220px)`);
	const { ref, controls } = useContentAnimation();

	return (
		<motion.section initial="hidden" animate={controls} variants={animationContainer}>
			<ScContainer>
				<ScReadyToStartSavingWrapper>
					<ScReadyToStartSavingTextWrapper>
						<div>
							<MotionDiv variants={fadeInRight}>
								<ScTitle
									isMobileLarge
									color={theme.colors.strongWhite}
									centered={!isDesktop}
									dangerouslySetInnerHTML={{ __html: title }}
								></ScTitle>
							</MotionDiv>
							<MotionDiv variants={fadeInRight}>
								<ScParagraph
									color={theme.colors.strongWhite}
									centered={!isDesktop}
									dangerouslySetInnerHTML={{ __html: text }}
									style={{ marginTop: `${!isDesktop ? '20px' : '10px'}`, gap: 0 }}
								></ScParagraph>
							</MotionDiv>
						</div>
						{!isDesktop && (
							<MotionImage variants={fadeInUp} src={image} alt="ready to start saving image" />
						)}
						<MotionDiv variants={fadeInRight}>
							<ScReadyToStartSavingButtonsWrapper ref={ref}>
								{children}
							</ScReadyToStartSavingButtonsWrapper>
						</MotionDiv>
					</ScReadyToStartSavingTextWrapper>
					{isDesktop && (
						<MotionImage variants={fadeInLeft} src={image} alt="ready to start saving image" />
					)}
				</ScReadyToStartSavingWrapper>
			</ScContainer>
		</motion.section>
	);
};
