import { useEffect, useRef } from 'react';
import React from 'react';
import { motion } from 'framer-motion';
import { useTheme } from 'styled-components';

import { isNotSSR } from '@utils';

import { MotionDiv } from '../../animations/components';
import { animationContainer, fadeInUp } from '../../animations/variants';
import image from '../../assets/images/bike-logo.png';
import image2 from '../../assets/images/nursery-logo.png';
import image3 from '../../assets/images/faq-tech-logo.png';
import { CustomButton } from '../../components/custom-button';
import { FaqAccordion } from '../../components/FaqAccordion/FaqAccordion';
import useContentAnimation from '../../hooks/use-content-animation';
import { scrollToRef } from '../../utils/scroll';

import {
	ScButtonWrapper,
	ScCalculatorAccordionWrapper,
	ScFaqCardWrapper,
	ScFaqSectionsWrapper,
	ScFaqTitle,
	ScTitleWrapper,
} from './styles';

interface IFaqSections {
	title: string;
	columns: number;
	text: string[];
}

export const FaqSections = ({
	title,
	accordionBikes,
	nurseriesNurseries,
  accordionTechAndHome
}: {
	title: string;
	accordionBikes: IFaqSections[];
	nurseriesNurseries: IFaqSections[];
  accordionTechAndHome: IFaqSections[];
}) => {
	const { ref: accordionRef, controls: accordionControls } = useContentAnimation();
	const { ref: accordionRefNursery, controls: accordionControlsNursery } = useContentAnimation();
	const { ref: accordionRefTechAndHome, controls: accordionControlsTechAndHome } = useContentAnimation();
	const theme = useTheme();
	const bike = useRef<HTMLInputElement | null>(null);
	useEffect(() => {
		if (isNotSSR) {
			const params = new URLSearchParams(window.location.search);
			if (params.get('section')) {
				if (accordionRefNursery.current) {
					accordionRefNursery.current.scrollIntoView({ behavior: 'smooth' });
				}
			}
		}
	}, [isNotSSR && window.location.search]);

	useEffect(() => {
    const handleHashScroll = () => {
      if (location.hash.includes('nursery-faqs')) {
        scrollToRef(accordionRefNursery);
      } else if (location.hash.includes('bike-faqs')) {
        scrollToRef(bike);
      } else if (location.hash.includes('tech-and-home-faqs')) {
        scrollToRef(accordionRefTechAndHome);
      }
    };

    const timeout = setTimeout(handleHashScroll, 100);

    return () => clearTimeout(timeout);
	}, [isNotSSR && window.location.hash]);

  const updateAnchor = (section: string) => {
    if (isNotSSR) {
      const url = new URL(window.location.href);
      url.hash = section;
      window.history.pushState({}, '', url.toString());
    }
  };

	return (
		<ScFaqSectionsWrapper ref={accordionRef}>
			<ScTitleWrapper>
				<h1>{title}</h1>
				<ScButtonWrapper>
					<CustomButton
						hoverBackground={theme.colors.strongWhite}
						background={theme.colors.strongWhite}
						hoverColor={theme.colors.darkText}
						color={theme.colors.darkText}
						onClick={() => {
              updateAnchor('bike-faqs');
              scrollToRef(bike);
            }}
					>
						Gogeta Bike
					</CustomButton>
					<CustomButton
						hoverBackground={theme.colors.strongWhite}
						background={theme.colors.strongWhite}
						hoverColor={theme.colors.darkText}
						color={theme.colors.darkText}
						onClick={() => {
              updateAnchor('nursery-faqs');
              scrollToRef(accordionRefNursery);
            }}
					>
						Gogeta Nursery
					</CustomButton>
          {/*<CustomButton*/}
          {/*  hoverBackground={theme.colors.strongWhite}*/}
          {/*  background={theme.colors.strongWhite}*/}
          {/*  hoverColor={theme.colors.darkText}*/}
          {/*  color={theme.colors.darkText}*/}
          {/*  onClick={() => {*/}
          {/*    updateAnchor('tech-and-home-faqs');*/}
          {/*    scrollToRef(accordionRefTechAndHome);*/}
          {/*  }}*/}
          {/*>*/}
          {/*  Gogeta Tech & Home*/}
          {/*</CustomButton>*/}
				</ScButtonWrapper>
			</ScTitleWrapper>
			<ScFaqCardWrapper ref={bike}>
				<img src={image as string} alt={'bike-img'} />
				<ScFaqTitle>Gogeta Bike</ScFaqTitle>
			</ScFaqCardWrapper>
			<motion.section initial="hidden" animate={accordionControls} variants={animationContainer}>
				<ScCalculatorAccordionWrapper id={'bike'}>
					{accordionBikes.map((item) => (
						<MotionDiv key={item.title} variants={fadeInUp}>
							<FaqAccordion
								title={item.title}
								text={item.text}
								columns={item.columns}
								key={item.title}
							/>
						</MotionDiv>
					))}
				</ScCalculatorAccordionWrapper>
				<div />
			</motion.section>
			<ScFaqCardWrapper ref={accordionRefNursery}>
				<img src={image2 as string} alt={'nursery-img'} />
				<ScFaqTitle>Gogeta Nursery</ScFaqTitle>
			</ScFaqCardWrapper>
			<motion.section
				initial="hidden"
				animate={accordionControlsNursery}
				variants={animationContainer}
			>
				<ScCalculatorAccordionWrapper id={'nursery'}>
					{nurseriesNurseries.map((item) => (
						<MotionDiv key={item.title} variants={fadeInUp}>
							<FaqAccordion
								title={item.title}
								text={item.text}
								columns={item.columns}
								key={item.title}
							/>
						</MotionDiv>
					))}
				</ScCalculatorAccordionWrapper>
			</motion.section>
      {/*<ScFaqCardWrapper ref={accordionRefTechAndHome}>*/}
      {/*  <img src={image3 as string} alt={'tech-img'} />*/}
      {/*  <ScFaqTitle>Gogeta Tech & Home</ScFaqTitle>*/}
      {/*</ScFaqCardWrapper>*/}
      {/*<motion.section*/}
      {/*  initial="hidden"*/}
      {/*  animate={accordionControlsTechAndHome}*/}
      {/*  variants={animationContainer}*/}
      {/*>*/}
      {/*  <ScCalculatorAccordionWrapper id={'tech'}>*/}
      {/*    {accordionTechAndHome.map((item) => (*/}
      {/*      <MotionDiv key={item.title} variants={fadeInUp}>*/}
      {/*        <FaqAccordion*/}
      {/*          title={item.title}*/}
      {/*          text={item.text}*/}
      {/*          columns={item.columns}*/}
      {/*          key={item.title}*/}
      {/*        />*/}
      {/*      </MotionDiv>*/}
      {/*    ))}*/}
      {/*  </ScCalculatorAccordionWrapper>*/}
      {/*</motion.section>*/}
		</ScFaqSectionsWrapper>
	);
};
