import { useState } from 'react';
import React from 'react';
import { navigate } from 'gatsby';

import Minus from '../../assets/icons/minus.png';
import Plus from '../../assets/icons/plus.png';
import { ScParagraph } from '../Paragraph/styled';

import {
	ScNavigationAccordionContent,
	ScNavigationAccordionItem,
	ScNavigationAccordionItemTitle,
} from './styled';

const newWindowUrls = [
  '/employee-cycling#savings-calculator',
  '/employer-cycling/plans/'
]

export const FaqAccordion = ({
	title,
	text,
	columns,
}: {
	title: string;
	text: string[];
	columns: number;
}) => {
	const [isOpen, setIsOpen] = useState(false);

	const handleLinkClick = (event: React.MouseEvent<HTMLElement>) => {
		const target = event.target as HTMLElement;
		if (target.tagName === 'A') {
			const href = target.getAttribute('href');

			if (href) {
				const isExternalLink = href.startsWith('http') || href.startsWith('https');

				if (!isExternalLink && !newWindowUrls.includes(href)) {
					event.preventDefault();
					navigate(href);
				}
			}
		}
	};

	return (
		<ScNavigationAccordionItem key={title}>
			<ScNavigationAccordionItemTitle onClick={() => setIsOpen(!isOpen)}>
				<p>{title}</p>
				{isOpen ? <img src={Minus} alt={'minus'}/> : <img src={Plus} alt={'Plus'}/>}
			</ScNavigationAccordionItemTitle>
			<ScNavigationAccordionContent columns={columns}>
				{text.map((item) => (
					<ScParagraph
						key={item}
						dangerouslySetInnerHTML={{ __html: item }}
						onClick={handleLinkClick}
					/>
				))}
			</ScNavigationAccordionContent>
		</ScNavigationAccordionItem>
	);
};
